<template>
  <v-container
    class="pa-0 px-0 pt-0 grey lighten-4"
    style="max-width: 600px; margin: 0 auto; min-height: 900px"
  >
    <v-banner single-line>
      カート
      <template v-slot:actions>
        <v-btn text color="light-blue" @click="handleClear">空にする</v-btn>
      </template>
    </v-banner>
    <div v-if="is_corporation">
      <v-list v-for="product in products" :key="product.sku_id">
        <CartItem :product="product" :handlePlus="handlePlus" :handleMinus="handleMinus" />
      </v-list>
    </div>
    <div v-else>
      <div v-if="!!products1.length">
        <div class="my-2 pa-2 orange darken-2 white--text">
          {{ startDay.format('最短 MM月DD日 に受取可能な商品') }}
        </div>
        <v-list v-for="product in products1" :key="product.sku_id">
          <CartItem :product="product" :handlePlus="handlePlus" :handleMinus="handleMinus" />
        </v-list>
      </div>
      <div v-if="!!products2.length">
        <div class="my-2 pa-2 orange darken-2 white--text">
          {{ startDay.add(1, 'd').format('最短 MM月DD日 に受取可能な商品') }}
        </div>
        <v-list v-for="product in products2" :key="product.sku_id">
          <CartItem :product="product" :handlePlus="handlePlus" :handleMinus="handleMinus" />
        </v-list>
      </div>
    </div>
    <div class="ma-2 mt-12">
      <div class="h6 text-end">小計(税込)：¥{{ total }}</div>
      <!-- <div class="caption text-end">¥{{ subtotal }}</div> -->
    </div>
    <div v-if="!is_corporation && payLeadTime" class="ma-2 mt-12">
      <v-radio-group :value="allInOne" @change="setAllInOne">
        <v-radio
          value="1"
          color="orange darken-2"
          :label="startDay.add(1, 'd').format('最短 MM月DD日 にまとめて受け取る')"
        ></v-radio>
        <v-radio color="orange darken-2" value="2" label="別々の日に最短で受け取る"></v-radio>
      </v-radio-group>
    </div>
    <div v-if="has_alcohol" class="my-10 text-center">
      <div class="text-subtitle-2 font-weight-bold">
        20歳未満の者の飲酒は法律で禁止されています。
      </div>
      <div class="text-subtitle-2 font-weight-bold">20歳未満の者に対して酒類を販売しません。</div>
    </div>
    <div class="text-center mt-4">
      <v-btn
        class="white--text"
        rounded
        color="orange darken-2"
        large
        :disabled="!total"
        @click="redirect()"
      >
        レジに進む
      </v-btn>
    </div>
    <BottomNavigation app></BottomNavigation>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';
  import BottomNavigation from '@/components/BottomNavigation.vue';
  import CartItem from './components/CartItem.vue';

  export default {
    name: 'Cart',
    components: {
      BottomNavigation,
      CartItem,
    },
    data() {
      return {
        products: [],
        products1: [],
        products2: [],
        subtotal: 0,
        total: 0,
        startDay: null,
      };
    },
    computed: {
      is_corporation() {
        return this.$store.state.user.is_corporation;
      },
      allInOne() {
        return this.$store.state.cart.allInOne;
      },
      payLeadTime() {
        if (!this.products.length) return false;
        return this.products1.length !== 0 && this.products1.length !== this.products.length;
      },
      has_alcohol() {
        return this.products.some((item) => item.is_alcohol);
      },
    },
    mounted() {
      if (this.$dayjs().get('hour') > 8) {
        this.startDay = this.$dayjs().add(1, 'day');
      } else {
        this.startDay = this.$dayjs();
      }
      let cart = this.$store.state.cart.cart || [];
      this.products = cart;
      this.products1 = cart.filter((item) => item.lead_time === 1);
      this.products2 = cart.filter((item) => item.lead_time === 2);
      this.handleSum();
    },

    methods: {
      setAllInOne(d) {
        this.$store.commit('cart/setAllInOne', d);
      },
      updateMyCartStorage() {
        this.$store.commit('cart/setMyCart', this.products);
      },
      handleSum() {
        this.total = this.products.reduce(
          (a, b) =>
            a +
            Math.floor(
              (b.is_onsale ? b.sku_sale_price : b.sku_price) * (1 + b.product_fields.tax),
            ) *
              b.sku_number,
          0,
        );
      },
      handleClear() {
        this.products = [];
        this.products1 = [];
        this.products2 = [];
        this.updateMyCartStorage();
        this.handleSum();
      },
      handlePlus(id) {
        this.products = this.products?.map((item) => {
          if (item.sku_id === id) {
            item.sku_number += 1;
          }
          return item;
        });
        this.updateMyCartStorage();
        this.handleSum();
      },
      handleMinus(id) {
        const fix = (item) => {
          if (item.sku_id === id) {
            if (item.sku_number === 1) {
              return [];
            } else {
              return [{ ...item, sku_number: item.sku_number - 1 }];
            }
          }
          return [item];
        };
        this.products = this.products?.flatMap(fix);
        this.products1 = this.products1?.flatMap(fix);
        this.products2 = this.products2?.flatMap(fix);
        this.updateMyCartStorage();
        this.handleSum();
      },
      async redirect() {
        try {
          const res = await Methods.checkProducts({
            productIds: this.products?.map(({ product_id }) => product_id),
          });
          const resIds = (res && res.data && res.data.productIds) || [];
          if (!resIds.length) {
            // 有货时确认是否成年和有酒
            if (!this.$store.state.user.is_adult) {
              if (this.has_alcohol) {
                return this.$router.push('/cart/adult');
              }
            }
            return this.$router.push('/pay');
          } else {
            const text = this.products
              .flatMap((item) =>
                resIds.includes(item.product_id) ? [item.product_fields.title] : [],
              )
              .join(' ＆ ');
            alert(
              `申し訳ございませんが、[${text}] は現在一時的に在庫切れとなっております。商品をカートから削除いただくと、レジに進むことができます。`,
            );
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>
